import { useSelector } from 'react-redux';

import useWindowSize from './useWindowSize';

import type { BannerAdsClickEventData } from 'events/sponsoredAdsBannerAds';
import type { PageViewPageType } from 'types/app';
import { isDesktopViewport, isMobileViewport } from 'utils/viewportUtil';
import { BANNER_ADS_MAX_STYLES } from 'constants/sponsoredAdsBannerAds';
import type { Product } from 'constants/searchTypes';
import { selectPageType } from 'selectors/pageView';
import { selectSearchTerm } from 'selectors/filters';
import { selectProductsList } from 'selectors/products';

interface SponsoredAdsBannerAdsClickEventParams {
  brand?: string;
  slot?: number;
}

export default ({ brand, slot }: SponsoredAdsBannerAdsClickEventParams): BannerAdsClickEventData => {
  const products = useSelector(selectProductsList) ?? [];
  const productStyleIds = products.slice(0, BANNER_ADS_MAX_STYLES).map((product: Product) => product.styleId);
  const { width = 0 } = useWindowSize(200);
  const isMobile = isMobileViewport(width);
  const isDesktop = isDesktopViewport(width);
  const pageType: PageViewPageType = useSelector(selectPageType) ?? {};
  const searchTerm = useSelector(selectSearchTerm);

  return {
    pageType: pageType === 'homepage' ? 'HOMEPAGE' : pageType === 'search' ? 'SEARCH_PAGE' : undefined,
    clientType: isMobile ? 'ZAPPOS_MOBILE' : isDesktop ? 'ZAPPOS_DESKTOP' : 'ZAPPOS_TABLET',
    searchTerm,
    brand: brand ?? undefined,
    matchStyles: productStyleIds,
    slot
  };
};
