import { BANNER_ADS_CLICK } from 'constants/amethyst';
import type { ZAPPOS_DESKTOP, ZAPPOS_MOBILE, ZAPPOS_TABLET } from 'constants/amethystClientTypes';
import type { HOMEPAGE, SEARCH_PAGE } from 'constants/amethystPageTypes';

export interface BannerAdsClickEventData {
  clientType: typeof ZAPPOS_DESKTOP | typeof ZAPPOS_TABLET | typeof ZAPPOS_MOBILE;
  pageType?: typeof HOMEPAGE | typeof SEARCH_PAGE;
  searchTerm?: string;
  brand?: string;
  matchStyles?: string[];
  slot?: number;
}

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/BannerAdsClick.proto
 */
export const evBannerAdsClickEvent = ({ clientType, pageType, searchTerm, brand, matchStyles, slot }: BannerAdsClickEventData) => ({
  [BANNER_ADS_CLICK]: {
    clientType,
    pageType,
    searchTerm,
    brand,
    matchStyles,
    slot
  }
});
