import { BannerAdInTest } from './BannerAd';

import type { IpStatus, PageInfo } from 'types/contentSymphony';
import type { SlotDetails } from 'types/landing';

export interface BannerAdSlotProps {
  slotDetails: SlotDetails;
  slotName?: string;
  slotIndex?: number;
  pageName?: string;
  pageInfo?: PageInfo;
  shouldLazyLoad?: boolean;
  slotHeartsData?: boolean;
  ipStatus?: IpStatus;
  isFullWidth?: boolean;
}

export const BannerAdSlot = (props: BannerAdSlotProps) => {
  const { slotName, slotIndex } = props;
  return (
    <div data-slot-id={slotName} data-slotindex={slotIndex}>
      <BannerAdInTest
        // class - breakdown
        // - padding of 48px on viewports 1440px and down to tablet above 768px.
        // - padding of 20px on viewports tablet and down under 768px
        className="my-12 px-5 [@media(min-width:769px)_and_(max-width:1440px)]:px-12"
        slotIndex={slotIndex}
      />
    </div>
  );
};
