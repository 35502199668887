import { track } from 'apis/amethyst';
import type { SponsoredAdsBannerAdsPageType, SponsoredAdsBannerAdsRequestBody } from 'apis/sponsoredAdsBannerAds';
import type { BannerAdPosition } from 'components/common/BannerAd/BannerAd.types';
import type { BannerAdsClickEventData } from 'events/sponsoredAdsBannerAds';
import { evBannerAdsClickEvent } from 'events/sponsoredAdsBannerAds';
import { trackSponsoredAdBannerAdsEvents } from 'hooks/useSponsoredAdsBannerAds';

export interface GetSearchBodyArgsParams {
  bannerAdPageType?: SponsoredAdsBannerAdsPageType;
  productStyleIds: string[];
  position: BannerAdPosition;
  brandNameFacets: string[];
  searchTerm: string;
}

type GetSearchBodyArgsResponse = Partial<Pick<SponsoredAdsBannerAdsRequestBody, 'matchStyles' | 'brands' | 'searchTerm'>>;

export const getSearchBodyArgs = ({
  bannerAdPageType,
  productStyleIds,
  position,
  brandNameFacets,
  searchTerm
}: GetSearchBodyArgsParams): GetSearchBodyArgsResponse => {
  if (bannerAdPageType !== 'SearchResults') {
    return {};
  }

  return {
    matchStyles: productStyleIds,
    brands: position === 'top' ? brandNameFacets : undefined,
    searchTerm: position === 'top' ? searchTerm : undefined
  };
};

export interface IsSearchAdsAvailableParams {
  bannerAdPageType?: SponsoredAdsBannerAdsPageType;
  productStyleIds: string[];
  position: BannerAdPosition;
  brandNameFacets: string[];
  searchTerm: string;
}

export const isSearchAdsAvailable = ({
  bannerAdPageType,
  productStyleIds,
  position,
  brandNameFacets,
  searchTerm
}: IsSearchAdsAvailableParams): boolean => {
  if (bannerAdPageType !== 'SearchResults') {
    return true;
  }

  if (!productStyleIds.length) {
    return false;
  }

  if (position === 'top' && !brandNameFacets?.length && !searchTerm) {
    return false;
  }

  return true;
};

export const trackClickEvent = (eventData: BannerAdsClickEventData, clickTracker?: string) => {
  track(() => [evBannerAdsClickEvent, eventData]);

  if (clickTracker) {
    trackSponsoredAdBannerAdsEvents({ url: clickTracker, fetcherName: 'SponsoredAdsBannerAdsClickTracker' });
  }
};
