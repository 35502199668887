import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { triggerAssignment } from 'actions/ab';
import { isValidTestTreatment } from 'helpers/SymphonyHydraTestValidator';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { slotContentTypes } from 'helpers/contentSlotConfig';

const renderSlot = (testTreatment, assignmentGroup, testName) => {
  if (assignmentGroup !== null) {
    return testTreatment[assignmentGroup] === 'Render';
  }
  return !testName;
};

export const LandingSlot = props => {
  const {
    isRecognized,
    triggerAssignment,
    hasAssignmentTriggered,
    pageName,
    pageInfo,
    slotName,
    data = {},
    onTaxonomyComponentClick,
    onComponentClick,
    shouldLazyLoad,
    slotHeartsData,
    ipStatus,
    slotContentTypesList = slotContentTypes,
    slotIndex,
    isFullWidth,
    slideWidths
  } = props;

  const { componentName, testName, testTreatment, testTrigger } = data;

  /*
   * Components can be reassigned through the Symphony testTreatment
   * attribute. Setting up initialized variables for reassignment
   * if needed.
   */
  let componentContent = data;
  let componentContentName = componentContent.componentName;

  /*
   * FUTURE:
   * Hook definition to set up test assignment for a Symphony component.
   * Evaluates the test attributes and trigger criteria before
   * assigning user to test. setShouldShow to true at this point
   * to avoid showing initial component, then swapping it on the next
   * tick with the treatment or fallback component.
   */
  useEffect(() => {
    const validTest = isValidTestTreatment({
      testName,
      testTrigger,
      testTreatment,
      isRecognized,
      hasAssignmentTriggered
    });

    if (validTest) {
      const { index = 0 } = triggerAssignment(testName) || {};
      setAssignmentGroup(index);
    }
    setShouldShow(true);
  }, [hasAssignmentTriggered, isRecognized, testName, testTreatment, testTrigger, triggerAssignment]);

  const [assignmentGroup, setAssignmentGroup] = useState(null);
  const shouldRenderSlot = renderSlot(testTreatment, assignmentGroup, testName);
  const [shouldShow, setShouldShow] = useState(shouldRenderSlot);
  if (!componentName) {
    return null;
  }

  /*
   * Checking for a testTreatment. If the assignmentGroup aligns
   * with a key containing a component object, overwrite the component data.
   * Else if there is no assignment, but there is a fallback component object,
   * overwrite with that. If there is a testName and no testTreatment assignment
   * or the assigment explicitly says not to render, set the component name to null.
   */
  if (testName && testTrigger && testTreatment) {
    const { fallback } = testTreatment;
    if (testTreatment[assignmentGroup] instanceof Object) {
      componentContent = testTreatment[assignmentGroup];
      componentContentName = testTreatment[assignmentGroup].componentName;
    } else if (!hasAssignmentTriggered && fallback instanceof Object) {
      componentContent = fallback;
      componentContentName = fallback.componentName;
    } else if ((!testTreatment[assignmentGroup] && !fallback) || testTreatment[assignmentGroup] === 'DoNotRender') {
      componentContentName = null;
    }
  }
  const SlotContent = slotContentTypesList[componentContentName];

  return SlotContent && (shouldRenderSlot || shouldShow) ? (
    <SlotContent
      slotName={slotName}
      slotDetails={componentContent}
      slotIndex={slotIndex}
      pageName={pageName}
      pageInfo={pageInfo}
      onComponentClick={onComponentClick}
      onTaxonomyComponentClick={onTaxonomyComponentClick}
      shouldLazyLoad={shouldLazyLoad}
      slotHeartsData={slotHeartsData}
      ipStatus={ipStatus}
      isFullWidth={isFullWidth}
      slideWidths={slideWidths}
    />
  ) : null;
};

const mapStateToProps = (state, ownProps) => ({
  isRecognized: !!state.cookies['x-main'],
  hasAssignmentTriggered: Object.keys(state.ab.assignments).includes(ownProps.data?.testName)
});

const mapDispatchToProps = {
  triggerAssignment
};

const ConnectedLandingSlot = connect(mapStateToProps, mapDispatchToProps)(LandingSlot);
export default withErrorBoundary('LandingSlot', ConnectedLandingSlot);
