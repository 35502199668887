import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import type { BannerAdProps } from './BannerAd.types';
import { containerClasses, imageClasses, linkClasses, sponsoredAfterClasses } from './BannerAd.classes';

import Image from 'components/common/Image';
import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { useSponsoredAdsBannerAds } from 'hooks/useSponsoredAdsBannerAds';
import useTrackSponsoredAdsBannerAds from 'hooks/useTrackSponsoredAdsBannerAds';
import { trackClickEvent } from 'utils/sponsoredAds/sponsoredAdsBannerAdsUtil';
import useSponsoredAdsBannerAdsClickEvent from 'hooks/useSponsoredAdsBannerAdsClickEvent';
import { INVIEW_THRESHOLD, PAGE_TYPE_BANNER_TYPE_MAP } from 'constants/sponsoredAdsBannerAds';
import type { PageViewPageType } from 'types/app';
import { selectPageType } from 'selectors/pageView';
import { useIsEnrolledInVariant } from 'hooks/unleash/useIsEnrolledInVariant';
import { FEATURE_BANNER_ADS, FEATURE_HOMEPAGE_BANNER_ADS } from 'constants/features';

export const BannerAdInTest = (bannerAdProps: BannerAdProps) => {
  const { isEnrolled: isShowBannerAdsFeature } = useIsEnrolledInVariant(FEATURE_BANNER_ADS);
  const { isEnrolled: isShowHomepageBannerAdsFeature } = useIsEnrolledInVariant(FEATURE_HOMEPAGE_BANNER_ADS);
  const pageType: PageViewPageType = useSelector(selectPageType) ?? {};
  const bannerAdPageType = PAGE_TYPE_BANNER_TYPE_MAP[pageType];

  if (bannerAdPageType === 'Homepage' && !isShowHomepageBannerAdsFeature) {
    return null;
  }

  if (bannerAdPageType === 'SearchResults' && !isShowBannerAdsFeature) {
    return null;
  }
  return <BannerAd {...bannerAdProps} />;
};

export const BannerAd = ({ 'data-test-id': dataTestId, position = 'bottom', className, slotIndex, ...forwardProps }: BannerAdProps) => {
  const { testId } = useMartyContext();
  const ads = useSponsoredAdsBannerAds({ position });
  const { bannerAds } = ads ?? {};
  const { assetUrl, clickThru = '', viewTracker, clickTracker, impressionTracker, brand } = bannerAds?.[0] ?? {};
  const hasAsset = Boolean(assetUrl?.length);
  const sponsoredClasses = assetUrl ? sponsoredAfterClasses : '';

  const [intersectionRef, inView] = useInView({
    threshold: INVIEW_THRESHOLD,
    triggerOnce: true
  });

  useTrackSponsoredAdsBannerAds({ viewTracker, impressionTracker, inView });
  const eventData = useSponsoredAdsBannerAdsClickEvent({ brand: brand ?? undefined, slot: slotIndex });

  // CLS ( on search top slot ), agreed as the go forward solution instead of using a placeholder fallback image.
  if (!hasAsset) {
    return null;
  }

  return (
    <div className={cn(containerClasses, className)} data-test-id={testId(dataTestId)} {...forwardProps}>
      <a
        className={cn(linkClasses, sponsoredClasses)}
        href={clickThru}
        onClick={() => trackClickEvent(eventData, clickTracker)}
        aria-label="sponsored banner ad"
        ref={intersectionRef}
      >
        <Image className={imageClasses} src={assetUrl} alt="bannerAd" />
      </a>
    </div>
  );
};
