import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useWindowSize from 'hooks/useWindowSize';
import type { SponsoredAdsBannerAdsRequestBody, SponsoredAdsBannerAdsResponse } from 'apis/sponsoredAdsBannerAds';
import { getSponsoredAdsBannerAds } from 'apis/sponsoredAdsBannerAds';
import useMartyContext from 'hooks/useMartyContext';
import {
  BANNER_ADS_MAX_ADS,
  BANNER_ADS_MAX_STYLES,
  BANNER_ADS_MIN_ADS,
  PAGE_TYPE_BANNER_SIZE_MAP,
  PAGE_TYPE_BANNER_TYPE_MAP
} from 'constants/sponsoredAdsBannerAds';
import type { PageViewPageType } from 'types/app';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { getViewportAsString, isMobileViewport } from 'utils/viewportUtil';
import type { Product } from 'constants/searchTypes';
import { getSearchBodyArgs, isSearchAdsAvailable } from 'utils/sponsoredAds/sponsoredAdsBannerAdsUtil';
import type { BannerAdPosition } from 'components/common/BannerAd/BannerAd.types';
import { selectPageType } from 'selectors/pageView';
import { selectCookiesSessionId } from 'selectors/cookies';
import { selectSearchTerm, selectSelectedFilters } from 'selectors/filters';
import { selectProductsList } from 'selectors/products';
import { selectDocumentMeta } from 'selectors/meta';
import timedFetch from 'middleware/timedFetch';
import { trackError } from 'helpers/ErrorUtils';

interface UseSponsoredAdsBannerAdsParams {
  fetchBanners?: Function;
  position: BannerAdPosition;
}

export const useSponsoredAdsBannerAds = ({ fetchBanners = getSponsoredAdsBannerAds, position = 'bottom' }: UseSponsoredAdsBannerAdsParams) => {
  const { environmentConfig: { api: { sponsoredAds: { url: sponsoredAdsUrl = '' } = {} } = {} } = {} } = useMartyContext();
  const [bannerAds, setBannerAds] = useState<SponsoredAdsBannerAdsResponse>();
  const { width = 0 } = useWindowSize(200);
  const isMobile = isMobileViewport(width);
  const { canonical: url } = useSelector(selectDocumentMeta) ?? {};
  const pageType: PageViewPageType = useSelector(selectPageType) ?? {};
  const products = useSelector(selectProductsList) ?? [];
  const sessionId = useSelector(selectCookiesSessionId);
  const selectedFilters = useSelector(selectSelectedFilters);
  const searchTerm = useSelector(selectSearchTerm);
  const allFilters = { ...selectedFilters?.singleSelects, ...selectedFilters?.multiSelects };
  const brandNameFacets = allFilters.brandNameFacet;
  const productStyleIds = products.slice(0, BANNER_ADS_MAX_STYLES).map((product: Product) => product.styleId);
  const bannerAdPageType = PAGE_TYPE_BANNER_TYPE_MAP[pageType];
  const imageSizes = PAGE_TYPE_BANNER_SIZE_MAP[pageType] ?? PAGE_TYPE_BANNER_SIZE_MAP['homepage'];
  const clientType = getViewportAsString(width);

  useEffect(() => {
    if (!sessionId || !sponsoredAdsUrl || !pageType || width === 0 || !clientType) {
      return;
    }

    if (!isSearchAdsAvailable({ brandNameFacets, position, productStyleIds, bannerAdPageType, searchTerm })) {
      return;
    }

    const body: SponsoredAdsBannerAdsRequestBody = {
      clientType,
      width: isMobile ? imageSizes?.mobile.width : imageSizes?.desktop.width,
      height: isMobile ? imageSizes?.mobile.height : imageSizes?.desktop.height,
      minimum: BANNER_ADS_MIN_ADS,
      maximum: BANNER_ADS_MAX_ADS,
      url: window.location.href,
      pageType: bannerAdPageType ?? 'Homepage',
      ...getSearchBodyArgs({ position, productStyleIds, brandNameFacets, bannerAdPageType, searchTerm })
    };

    const controller = new AbortController();

    const fetchBannerAds = async () => {
      try {
        const adsResponse = await fetchBanners({
          sponsoredAdsUrl,
          body,
          controller
        });

        const ads: SponsoredAdsBannerAdsResponse = await fetchErrorMiddleware(adsResponse);
        if (!ads) {
          return;
        }
        setBannerAds(ads);
      } catch (e) {
        return;
      }
    };

    fetchBannerAds();

    return () => {
      controller.abort();
    };
  }, [brandNameFacets?.length, searchTerm, productStyleIds?.length, url, sessionId, sponsoredAdsUrl, pageType, width, clientType]);

  return bannerAds;
};

export const trackSponsoredAdBannerAdsEvents = ({ url, fetcherName }: { url: string; fetcherName: string }, fetcher = timedFetch) => {
  const fetch = fetcher(fetcherName);
  fetch(url).catch((error: any) => trackError('ERROR', 'Failed to send MSFT banner ad event', error));
};
