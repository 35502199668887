import { useEffect } from 'react';

import { trackSponsoredAdBannerAdsEvents } from './useSponsoredAdsBannerAds';

interface TrackSponsoredAdsBannerAdsProps {
  viewTracker?: string;
  inView?: boolean;
  impressionTracker?: string;
}

export default ({ viewTracker, impressionTracker, inView }: TrackSponsoredAdsBannerAdsProps) => {
  useEffect(() => {
    if (viewTracker && inView) {
      trackSponsoredAdBannerAdsEvents({ url: viewTracker, fetcherName: 'SponsoredAdsBannerAdsViewEvent' });
    }
  }, [viewTracker, inView]);

  useEffect(() => {
    if (impressionTracker) {
      trackSponsoredAdBannerAdsEvents({ url: impressionTracker, fetcherName: 'SponsoredAdsBannerAdsImpressions' });
    }
  }, [impressionTracker]);
};
